import { View, Text, TouchableOpacity, Platform } from "react-native";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setScreen } from "../store/tabSlice";

import { theme } from "../constants";
import { svg } from "../svg";
import { screens } from "../screens";
import { useTranslation } from "react-i18next";

const TabNavigator = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();

  const currentScreen = useSelector((state: any) => state.tab.screen);

  enum Tab {
    Dashboard = "Dashboard",
    Profile = "Profile",
    Trade = "Trade",
    Loans = "Loans",
    Notifications = "Notifications",
    Follow = "FollowTitle",
    OpenAccount = "Open Account",
  }

  const tabs = [
    {
      name: Tab.Dashboard,
      icon:
        currentScreen === Tab.Dashboard ? <svg.HomeFillSvg /> : <svg.HomeSvg />,
    },

    {
      name: Tab.Trade,
      icon:
        currentScreen === Tab.Trade ? <svg.TradeFillSvg /> : <svg.TradeSvg />,
    },
    {
      name: Tab.Follow,
      icon:
        currentScreen === Tab.Follow ? (
          <svg.FollowFillSvg />
        ) : (
          <svg.FollowSvg />
        ),
    },
    {
      name: Tab.Profile,
      icon:
        currentScreen === Tab.Profile ? (
          <svg.ProfileFillSvg />
        ) : (
          <svg.ProfileSvg />
        ),
    },
  ];

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: theme.COLORS.bgColor,
        maxHeight: Platform.OS == "web" ? "100vh" : "",
      }}
    >
      {currentScreen === Tab.Dashboard && <screens.Dashboard />}
      {currentScreen === Tab.Trade && <screens.Trade />}
      {/* {currentScreen === Tab.Loans && <screens.Loans />} */}
      {currentScreen === Tab.Notifications && <screens.Notification />}
      {currentScreen === Tab.Follow && <screens.Follow />}
      {currentScreen === Tab.Profile && <screens.Profile />}
      {currentScreen === Tab.OpenAccount && <screens.OpenAccount />}
      <View
        style={{
          width: "100%",
          borderRadius: 10,
          backgroundColor: "#FCF8F6",
          position: Platform.OS == "web" ? "fixed" : "sticky",
          bottom: Platform.OS == "web" && "0",
        }}
      >
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            paddingHorizontal: 0,
            paddingVertical: 10,
            backgroundColor: "#FCF8F6",
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
        >
          {tabs.map((item, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={() => dispatch(setScreen(item.name))}
              >
                <View
                  style={{
                    alignSelf: "center",
                    marginBottom: 6,
                  }}
                >
                  {item.icon}
                </View>
                <Text
                  style={{
                    textAlign: "center",
                    ...theme.FONTS.Mulish_600SemiBold,
                    fontSize: 10,
                    color:
                      item.name === currentScreen
                        ? theme.COLORS.linkColor
                        : theme.COLORS.mainDark,
                  }}
                >
                  {t(item.name)}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    </View>
  );
};

export default TabNavigator;
