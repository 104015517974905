import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import {
  Image,
  ScrollView,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import Icon from "react-native-vector-icons/FontAwesome";
import { RefreshControl } from "react-native-web-refresh-control";
import { components } from "../components";
import {
  ApEmptyState,
  ApLoader,
  ApMarketType,
  ApPlatformTabs,
  ApRow,
  ApSafeAreaView,
} from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useStrategyState, useTradingContext } from "../context";
import { useFollowContext } from "../context/follow";
import { svg } from "../svg";
import { toCurrency } from "../utils";
import { useTranslation } from "react-i18next";
const cards = [
  {
    id: "1",
    title: "Visa",
    number: "**** **** **** 7895",
    amount: "4 863.27",
    icon: "",
    // icon: require("../assets/cards/06.png"),
  },
  {
    id: "2",
    title: "Visa",
    number: "**** **** **** 3964",
    amount: "4 863.27",
    icon: "",
    // icon: require("../assets/cards/06.png"),
  },
  {
    id: "3",
    title: "Visa",
    number: "**** **** **** 1045",
    amount: "4 863.27",
    icon: "",
    // icon: require("../assets/cards/06.png"),
  },
];

const depositPeriods = [
  {
    id: "1",
    title: "3 month",
  },
  {
    id: "2",
    title: "6 month",
  },
  {
    id: "3",
    title: "12 month",
  },
  {
    id: "4",
    title: "18 month",
  },
  {
    id: "5",
    title: "24 month",
  },
  {
    id: "6",
    title: "36 month",
  },
];

const FollowStrategy: React.FC = () => {
  const [deposits, setDeposits] = useState(true);
  const [openDeposit, setOpenDeposit] = useState(false);
  const [openMoneyBox, setOpenMoneyBox] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [currency, setCurrency] = useState("USD");
  const [depositPeriod, setDepositPeriod] = useState("3 month");
  const [moneyBoxPerDay, setMoneyBoxPerDay] = useState(true);
  const [dollarPerTransaction, setDollarPerTransaction] = useState(false);
  const [tenDollarPerTransaction, setTenDollarPerTransaction] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const navigation: any = useNavigation();
  const searchInputRef = useRef<TextInput>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<any>();
  const { t } = useTranslation();

  // const [platform, setPlatform] = useState<string>("1");
  // const [market, setMarket] = useState<string>(MARKET_TYPES[0].value);
  const {
    loading,
    getCopyStrategyBotPlans,
    copyStrategyBotPlans,
    activeBotPlan,
    setActiveBotP,
  } = useFollowContext();

  const [follows, setFollows] = useState([]);

  const { setPlatform, setMarket, market, platform } = useTradingContext();

  useEffect(() => {
    getCopyStrategyBotPlans(platform, market, true);
  }, []);

  useEffect(() => {
    setFollows(copyStrategyBotPlans);
  }, [copyStrategyBotPlans, market]);

  const handleRefresh = () => {
    setRefreshing(true);
    setMarket(`2`);
    Promise.all([getCopyStrategyBotPlans(platform, "2", true)]).finally(() => {
      setRefreshing(false);
    });
  };

  const renderHeader = () => {
    return <components.Header goBack={true} title="Follow Strategy" titleTranlateKey="FollowStrategy" />;
  };

  const { setSymbols } = useStrategyState();

  const handleFilterChange = (list) => {
    setFollows(list);
  };

  const renderDeposit = () => {
    return (
      <View style={{ paddingHorizontal: 15 }}>
        <View
          style={{
            justifyContent: "space-between",
            borderRadius: 50,
            backgroundColor: follows?.length > 0 ? "#FFF6F1" : "none",
          }}
        >
          <View
            style={{
              padding: 10,
              paddingTop: 15,
              paddingBottom: 0,
              backgroundColor: follows?.length > 0 ? "#FFF6F1" : "none",
              borderRadius: 10,
            }}
          >
            {!isSearching && follows.filter((item) => item?.market === market).length === 0 ? (
              <ApEmptyState
                heading="Follow"
                message="No follow records found"
              />
            ) : (
              isSearching ?
              (follows
                .filter((item) => item?.market === market)
                .filter((item) => 
                  (item?.label_code.toLowerCase()
                  .includes(query.toLowerCase())) ||
                  (item?.trader?.label.toLowerCase()
                  .includes(query.toLowerCase()))
              )).map((e, index) => (
                <View
                  key={`${index}_follow`}
                  style={{
                    padding: 15,
                    borderRadius: 10,
                    marginBottom: 20,
                    borderWidth: 2,
                    borderColor: "#F37021",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ApText
                      style={{
                        fontSize: 14,
                        fontWeight: "500",
                      }}
                    >
                      {e?.label_code}
                    </ApText>

                    <View style={{ flex: 1, width: "20%" }}>
                      <ApText
                        translateKey={e?.trader?.label}
                        style={{
                          fontSize: 13,
                          textAlign: "right",
                          color: "#222",
                        }}
                      >
                        {e?.trader?.label}
                      </ApText>
                    </View>
                  </View>

                  <ApRow>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 5,
                        paddingBottom: 10,
                        alignItems: "center"
                      }}
                    >
                      <ApText
                        style={{
                          fontSize: 12,
                          color: "#878787",
                          marginRight: 5,
                        }}
                      >
                        {e?.followers}
                      </ApText>
                      <ApText
                        translateKey="Followers"
                        style={{ fontSize: 12, color: "#878787" }}
                      />

                      {e?.plan_type == "1" && (
                        <View style={styles.botPlanTypeOneLabel}>
                          <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                        </View>
                      )}
                    </View>

                    <ApText
                      style={{ fontSize: 12, color: "#878787" }}
                      translateKey={`TraderTypes.${e?.trader?.trader_type}`}
                    />
                  </ApRow>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      borderBottomWidth: 1,
                      borderBottomColor: "#CED6E1",
                    }}
                  ></View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <ApText
                        translateKey="new30dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_30d}%
                      </ApText>
                      <ApText
                        translateKey="totalPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_total}%
                      </ApText>
                      { /*<ApText
                        translateKey="TotalFollowPL"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(e?.trader.pnl_amt_total)}
                        {" USDT"}
                      </ApText>
                      <ApText
                        translateKey="30dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(parseFloat(e?.trader.pnl_perc_30d) * 100)}{" "}
                        %
                      </ApText>*/}
                      <ApText
                        translateKey="Direction"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      {market === "2" ? (
                        <ApText
                          translateKey={
                            e?.direction === "1"
                              ? "Long"
                              : e?.direction === "2"
                              ? "Short"
                              : "Both"
                          }
                          style={{
                            marginBottom: 12,
                            fontWeight: "500",
                            color: theme.COLORS.mainDark,
                            fontSize: 13,
                          }}
                        />
                      ) : (
                        <ApText
                          translateKey={
                            e?.direction === "1"
                              ? "USDT_Based"
                              : "Token_Based"
                          }
                          style={{
                            marginBottom: 12,
                            fontWeight: "500",
                            color: theme.COLORS.mainDark,
                            fontSize: 13,
                          }}
                        />
                      )}
                    </View>
                    <View style={{ flex: 1 }}>
                        
                      <ApText
                        translateKey="90dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_90d}%
                      </ApText>
                      { /*<ApText
                        translateKey="30dFollowPLAmount"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(e?.trader.pnl_amt_30d)}
                        {" USDT"}
                      </ApText>
                      <ApText
                        translateKey="30dPLTrades"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.trader.trades_30d}
                      </ApText> */ }
                      <ApText
                        translateKey="TotalEntrySize"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.total_entry_size}
                      </ApText>
                    </View>
                  </View>

                  <View style={{ alignItems: "flex-end" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setSymbols([]);
                        setActiveBotP(e).then((rs) => {
                          navigation.navigate("StrategyDetails", {
                            bp_id: e?.id,
                          });
                        });
                      }}
                      style={{
                        backgroundColor: "#F37021",
                        width: 100,
                        height: 30,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="StartBot"
                        style={{
                          fontSize: 13,
                          color: theme.COLORS.white,
                          fontWeight: "500",
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
                )) 
              : follows
              .filter((item) => item?.market === market)
              .map((e, index) => (
                <View
                  key={`${index}_follow`}
                  style={{
                    padding: 15,
                    borderRadius: 10,
                    marginBottom: 20,
                    borderWidth: 2,
                    borderColor: "#F37021",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ApText
                      style={{
                        fontSize: 14,
                        fontWeight: "500",
                      }}
                    >
                      {e?.label_code}
                    </ApText>

                    <View style={{ flex: 1, width: "20%" }}>
                      <ApText
                        translateKey={e?.trader?.label}
                        style={{
                          fontSize: 13,
                          textAlign: "right",
                          color: "#222",
                        }}
                      >
                        {e?.trader?.label}
                      </ApText>
                    </View>
                  </View>

                  <ApRow>
                    <View
                      style={{
                        flexDirection: "row",
                        paddingTop: 5,
                        paddingBottom: 10,
                        alignItems: "center"
                      }}
                    >
                      <ApText
                        style={{
                          fontSize: 12,
                          color: "#878787",
                          marginRight: 5,
                        }}
                      >
                        {e?.followers}
                      </ApText>
                      <ApText
                        translateKey="Followers"
                        style={{ fontSize: 12, color: "#878787" }}
                      />

                      {e?.plan_type == "1" && (
                        <View style={styles.botPlanTypeOneLabel}>
                          <ApText size="xs" color="#fff" translateKey="BotPlanTypeOneLabel"/>
                        </View>
                      )}
                    </View>

                    <ApText
                      style={{ fontSize: 12, color: "#878787" }}
                      translateKey={`TraderTypes.${e?.trader?.trader_type}`}
                    />
                  </ApRow>

                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                      borderBottomWidth: 1,
                      borderBottomColor: "#CED6E1",
                    }}
                  ></View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "flex-start",
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <ApText
                        translateKey="new30dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_30d}%
                      </ApText>
                      <ApText
                        translateKey="totalPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_total}%
                      </ApText>
                      { /*<ApText
                        translateKey="TotalFollowPL"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(e?.trader.pnl_amt_total)}
                        {" USDT"}
                      </ApText>
                      <ApText
                        translateKey="30dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(parseFloat(e?.trader.pnl_perc_30d) * 100)}{" "}
                        %
                      </ApText> */ }
                      <ApText
                        translateKey="Direction"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      {market === "2" ? (
                        <ApText
                          translateKey={
                            e?.direction === "1"
                              ? "Long"
                              : e?.direction === "2"
                              ? "Short"
                              : "Both"
                          }
                          style={{
                            marginBottom: 12,
                            fontWeight: "500",
                            color: theme.COLORS.mainDark,
                            fontSize: 13,
                          }}
                        />
                      ) : (
                        <ApText
                          translateKey={
                            e?.direction === "1"
                              ? "USDT_Based"
                              : "Token_Based"
                          }
                          style={{
                            marginBottom: 12,
                            fontWeight: "500",
                            color: theme.COLORS.mainDark,
                            fontSize: 13,
                          }}
                        />
                      )}
                    </View>
                    <View style={{ flex: 1 }}>
                      <ApText
                        translateKey="90dPLPerc"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.pnl_perc_90d}%
                      </ApText>
                      { /* <ApText
                        translateKey="30dFollowPLAmount"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                          marginTop: 12,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {toCurrency(e?.trader.pnl_amt_30d)}
                        {" USDT"}
                      </ApText>
                      <ApText
                        translateKey="30dPLTrades"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.trader.trades_30d}
                      </ApText> */ }
                      <ApText
                        translateKey="TotalEntrySize"
                        style={{
                          ...theme.FONTS.Mulish_400Regular,
                          fontSize: 12,
                          lineHeight: 12 * 1.6,
                          color: theme.COLORS.bodyTextColor,
                        }}
                      />
                      <ApText
                        style={{
                          marginBottom: 12,
                          fontWeight: "500",
                          color: theme.COLORS.mainDark,
                          fontSize: 13,
                        }}
                      >
                        {e?.total_entry_size}
                      </ApText>
                    </View>
                  </View>

                  <View style={{ alignItems: "flex-end" }}>
                    <TouchableOpacity
                      onPress={() => {
                        setSymbols([]);
                        setActiveBotP(e).then((rs) => {
                          navigation.navigate("StrategyDetails", {
                            bp_id: e?.id,
                          });
                        });
                      }}
                      style={{
                        backgroundColor: "#F37021",
                        width: 100,
                        height: 30,
                        borderRadius: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ApText
                        translateKey="StartBot"
                        style={{
                          fontSize: 13,
                          color: theme.COLORS.white,
                          fontWeight: "500",
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ))
            )}

            {isSearching && follows
              .filter((item) => item?.market === market)
              .filter((item) => 
                (item?.label_code.toLowerCase()
                .includes(query.toLowerCase())) ||
                (item?.trader?.label.toLowerCase()
                .includes(query.toLowerCase()))
              ).length === 0 && (
                <ApEmptyState
                  heading="Follow"
                  message="No follow records found"
                />
              )}
          </View>
        </View>
      </View>
    );
  };

  const renderOpenDeposit = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 47,
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
              }}
              onPress={() => {
                setOpenDeposit(false);
                setDeposits(true);
                setOpenMoneyBox(false);
              }}
            >
              <svg.GoBackSvg />
            </TouchableOpacity>
          </View>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Open deposit
          </ApText>
        </View>
        <View>
          <components.SmallHeader
            title="Choose currency:"
            containerStyle={{ marginBottom: 6, marginTop: 20 }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 14,
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 8,
                backgroundColor:
                  currency === "USD"
                    ? theme.COLORS.mainDark
                    : theme.COLORS.white,
                borderRadius: 10,
                marginRight: 14,
              }}
              onPress={() => setCurrency("USD")}
            >
              <ApText
                style={{
                  ...theme.FONTS.H5,
                  color:
                    currency === "USD"
                      ? theme.COLORS.white
                      : theme.COLORS.mainDark,
                }}
              >
                USD
              </ApText>
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 8,
                backgroundColor:
                  currency === "EUR"
                    ? theme.COLORS.mainDark
                    : theme.COLORS.white,
                borderRadius: 10,
              }}
              onPress={() => setCurrency("EUR")}
            >
              <ApText
                style={{
                  ...theme.FONTS.H5,
                  color:
                    currency === "EUR"
                      ? theme.COLORS.white
                      : theme.COLORS.mainDark,
                }}
              >
                EUR
              </ApText>
            </TouchableOpacity>
          </View>
          <components.SmallHeader
            title="Choose deposit period:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View
            style={{
              flexDirection: "row",
              flexWrap: "wrap",
              marginBottom: 4,
            }}
          >
            {depositPeriods.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    backgroundColor:
                      depositPeriod === item.title
                        ? theme.COLORS.mainDark
                        : theme.COLORS.white,
                    paddingHorizontal: 20,
                    paddingVertical: 8,
                    borderRadius: 10,
                    marginBottom: 10,
                    marginRight: 10,
                  }}
                  onPress={() => setDepositPeriod(item.title)}
                >
                  <ApText
                    style={{
                      ...theme.FONTS.Mulish_400Regular,
                      fontSize: 16,
                      lineHeight: 16 * 1.6,
                      color:
                        depositPeriod === item.title
                          ? theme.COLORS.white
                          : theme.COLORS.bodyTextColor,
                    }}
                  >
                    {item.title}
                  </ApText>
                </TouchableOpacity>
              );
            })}
          </View>
          <components.SmallHeader
            title="Amount:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 14,
            }}
          >
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                paddingHorizontal: 20,
                paddingVertical: 14,
                marginRight: 14,
                width: theme.SIZES.width / 2 - 20,
              }}
            >
              <TextInput
                placeholder="$1 000"
                style={{
                  ...theme.FONTS.Mulish_500Medium,
                  fontSize: 28,
                  color: theme.COLORS.mainDark,
                  textAlign: "left",
                }}
                numberOfLines={1}
                textAlign="left"
              />
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 12,
                color: theme.COLORS.bodyTextColor,
              }}
            >
              You rate is 8%.
            </ApText>
          </View>
          <components.SmallHeader
            title="Use card:"
            containerStyle={{ marginBottom: 6 }}
          />
          <View style={{ marginBottom: 30 }}>
            {cards?.map((item, index) => {
              return (
                <TouchableOpacity
                  key={index}
                  style={{
                    backgroundColor: theme.COLORS.white,
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    marginRight: 14,
                  }}
                >
                  <Image
                    source={item.icon}
                    style={{
                      width: 72,
                      height: 46,
                      marginRight: 14,
                    }}
                  />
                  <View>
                    <ApText
                      style={{
                        ...theme.FONTS.Mulish_400Regular,
                        fontSize: 12,
                        color: theme.COLORS.bodyTextColor,
                      }}
                    >
                      {item.number}
                    </ApText>
                    <ApText
                      style={{
                        ...theme.FONTS.H6,
                        color: theme.COLORS.mainDark,
                      }}
                    >
                      {item.amount}
                    </ApText>
                  </View>
                </TouchableOpacity>
              );
            })}
          </View>

          <View
            style={{
              marginBottom: theme.SIZES.height * 0.08,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottomWidth: 1,
              borderBottomColor: "#CED6E1",
              paddingBottom: 20,
            }}
          >
            <ApText
              style={{
                ...theme.FONTS.H5,
                color: theme.COLORS.mainDark,
              }}
            >
              Early loan repayment
            </ApText>
            <TouchableOpacity
              style={{
                width: 40,
                backgroundColor: toggle
                  ? theme.COLORS.green
                  : theme.COLORS.grey1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: toggle ? "flex-end" : "flex-start",
                padding: 2,
                borderRadius: 20,
              }}
              onPress={() => setToggle(!toggle)}
              activeOpacity={0.8}
            >
              <View
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: 12,
                  backgroundColor: theme.COLORS.white,
                }}
              />
            </TouchableOpacity>
          </View>
          <components.Button
            title="Open deposit"
            containerStyle={{ marginBottom: 20 }}
            onPress={() => {
              setDeposits(true);
              setOpenMoneyBox(false);
              setOpenDeposit(false);
            }}
          />
        </View>
      </View>
    );
  };

  const renderOpenMoneybox = () => {
    return (
      <View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            height: 47,
          }}
        >
          <View
            style={{
              position: "absolute",
              left: 0,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={{
                paddingHorizontal: 20,
                paddingVertical: 12,
              }}
              onPress={() => {
                setOpenDeposit(false);
                setDeposits(true);
                setOpenMoneyBox(false);
              }}
            >
              <svg.GoBackSvg />
            </TouchableOpacity>
          </View>
          <ApText
            style={{
              textAlign: "center",
              ...theme.FONTS.H4,
              color: theme.COLORS.mainDark,
            }}
          >
            Open moneybox
          </ApText>
        </View>
        <View>
          <ScrollView
            contentContainerStyle={{ marginBottom: 70, flexGrow: 1 }}
            showsVerticalScrollIndicator={false}
          >
            <components.SmallHeader
              title="The amount you want to achieve:"
              containerStyle={{
                marginTop: 20,
                paddingHorizontal: 20,
                marginBottom: 6,
              }}
            />
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                paddingHorizontal: 20,
                paddingVertical: 14,
                marginRight: 14,
                width: theme.SIZES.width / 2 - 20,
                marginHorizontal: 20,
                marginBottom: 14,
              }}
            >
              <TextInput
                placeholder="$1200"
                style={{
                  ...theme.FONTS.Mulish_500Medium,
                  fontSize: 28,
                  color: theme.COLORS.mainDark,
                  textAlign: "left",
                }}
                numberOfLines={1}
                textAlign="left"
              />
            </View>
            <components.SmallHeader
              title="Use card:"
              containerStyle={{
                paddingHorizontal: 20,
                marginBottom: 6,
              }}
            />
            <View style={{ marginBottom: 30 }}>
              <ScrollView
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingLeft: 20 }}
              >
                {cards.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={{
                        backgroundColor: theme.COLORS.white,
                        flexDirection: "row",
                        alignItems: "center",
                        padding: 12,
                        marginRight: 14,
                      }}
                    >
                      <Image
                        source={item.icon}
                        style={{
                          width: 72,
                          height: 46,
                          marginRight: 14,
                        }}
                      />
                      <View>
                        <ApText
                          style={{
                            ...theme.FONTS.Mulish_400Regular,
                            fontSize: 12,
                            color: theme.COLORS.bodyTextColor,
                          }}
                        >
                          {item.number}
                        </ApText>
                        <ApText
                          style={{
                            ...theme.FONTS.H6,
                            color: theme.COLORS.mainDark,
                          }}
                        >
                          {item.amount}
                        </ApText>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          </ScrollView>
        </View>
        <View style={{ paddingHorizontal: 20, marginBottom: 20 }}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 10,
            }}
            onPress={() => {
              setMoneyBoxPerDay(true);
              setDollarPerTransaction(false);
              setTenDollarPerTransaction(false);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {moneyBoxPerDay && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <View
              style={{
                paddingHorizontal: 17,
                paddingVertical: 14,
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                marginRight: 10,
              }}
            >
              <ApText
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 14,
                  lineHeight: 14 * 1.6,
                  color: theme.COLORS.mainDark,
                }}
              >
                10.00
              </ApText>
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
            >
              USD per day;
            </ApText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 2,
            }}
            onPress={() => {
              setMoneyBoxPerDay(false);
              setDollarPerTransaction(true);
              setTenDollarPerTransaction(false);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {dollarPerTransaction && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
            >
              Rounding up to $1 per transaction;
            </ApText>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={() => {
              setMoneyBoxPerDay(false);
              setDollarPerTransaction(false);
              setTenDollarPerTransaction(true);
            }}
          >
            <View
              style={{
                width: 18,
                height: 18,
                borderWidth: 2,
                borderRadius: 18 / 2,
                borderColor: "#CED6E1",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 14,
              }}
            >
              {tenDollarPerTransaction && (
                <View
                  style={{
                    width: 10,
                    height: 10,
                    borderRadius: 10 / 2,
                    backgroundColor: theme.COLORS.green,
                  }}
                />
              )}
            </View>
            <ApText
              style={{
                ...theme.FONTS.Mulish_400Regular,
                fontSize: 16,
                lineHeight: 16 * 1.6,
                color: theme.COLORS.bodyTextColor,
              }}
            >
              Rounding up to $10 per transaction.
            </ApText>
          </TouchableOpacity>
        </View>
        <View
          style={{
            paddingHorizontal: 20,
            marginBottom: theme.SIZES.height * 0.1,
          }}
        >
          <components.InputField placeholder="Enter your goal" />
        </View>
        <components.Button
          title="Open Moneybox"
          containerStyle={{ paddingHorizontal: 20 }}
          onPress={() => {
            setOpenDeposit(false);
            setDeposits(true);
            setOpenMoneyBox(false);
          }}
        />
      </View>
    );
  };

  return (
    <ApSafeAreaView
      style={{
        marginBottom: 20,
      }}
    >
      {renderHeader()}
      {loading ? (
        <ApLoader />
      ) : (
        <>
          <View style={{ marginHorizontal: 15, marginTop: 10 }}>
            <ApPlatformTabs selected={platform} onSelect={setPlatform} />
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Icon name="warning" size={40} color="#fccc4d" />
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 10,
                      marginLeft: 5,
                      textAlign: "justify",
                      lineHeight: 14,
                    }}
                    translateKey="FollowPageNote"
                  />
                </View>

                <ApText
                  style={{
                    color: "red",
                    fontSize: 10,
                    textAlign: "justify",
                    lineHeight: 14,
                  }}
                  translateKey="FollowPageNote2"
                />
              </View>
            </View>

            <components.SearchInputField
                inputRef={searchInputRef}
                onChange={(e: any) => {
                  e.target.value === ""
                    ? setIsSearching(false)
                    : setIsSearching(true);
                  setQuery(e.target.value);
                }}
                containerStyle={{ marginTop: 20 }}
                leftIcon={<svg.SearchSvg />}
                placeholder={t("CopyStrategySearchPlaceholder")}
              />

            <View style={{ marginTop: -5 }}>
              <ApMarketType selected={market} onSelect={setMarket} />
            </View>
          </View>
          <RenderFilter
            market={market}
            list={
              isSearching ? copyStrategyBotPlans
              .filter((item) => item?.market === market)
              .filter((item) => 
                (item?.label_code.toLowerCase()
                .includes(query.toLowerCase())) ||
                (item?.trader?.label.toLowerCase()
                .includes(query.toLowerCase()))
              ) : copyStrategyBotPlans?.filter((item) => item?.market === market)
            }
            onChange={handleFilterChange}
          />
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={handleRefresh}
              />
            }
          >
            {deposits && renderDeposit()}
            {openDeposit && renderOpenDeposit()}
            {openMoneyBox && renderOpenMoneybox()}
          </ScrollView>
        </>
      )}
    </ApSafeAreaView>
  );
};

export default FollowStrategy;

const RenderFilter = ({ list, onChange, market }) => {
  const [traders, setTraders] = useState([]);
  const [teams, setTeams] = useState([]);
  const [active, setActive] = useState<"all" | "global" | "team">("all");

  useEffect(() => {
    // setActive("all");
    setTraders(list.filter((t) => t?.trader?.trader_type == "global"));
    setTeams(list.filter((t) => t?.trader?.trader_type == "team"));
  }, [list]);

  useEffect(() => {
    setActive("all");
  }, [market]);

  const RenderButton = ({ label, type, count }) => (
    <ApRow direction="row">
      <ApText
        style={[
          styles.traderTypeText,
          active == type ? styles.traderTypeActive : styles.traderTypeInactive,
        ]}
        translateKey={label}
      />
      <ApText
        style={[
          styles.traderTypeText,
          active == type ? styles.traderTypeActive : styles.traderTypeInactive,
        ]}
      >
        ( {count} )
      </ApText>
    </ApRow>
  );

  return (
    <>
      <ApRow
        style={{
          margin: 15,
          marginTop: -5,
          marginBottom: -5,
          padding: 5,
          paddingBottom: 10,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          backgroundColor: "rgba(255, 245, 240, 0.73)",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            onChange(list);
            setActive("all");
          }}
        >
          <RenderButton label={"All"} type={"all"} count={list?.length} />
        </TouchableOpacity>
        <ApText style={styles.traderTypeText}>|</ApText>
        <TouchableOpacity
          onPress={() => {
            onChange(traders);
            setActive("global");
          }}
        >
          <RenderButton
            label={"TraderTypes.global"}
            type={"global"}
            count={traders?.length}
          />
        </TouchableOpacity>
        <ApText style={styles.traderTypeText}>|</ApText>
        <TouchableOpacity
          onPress={() => {
            onChange(teams);
            setActive("team");
          }}
        >
          <RenderButton
            label={"TraderTypes.team"}
            type={"team"}
            count={teams?.length}
          />
        </TouchableOpacity>
      </ApRow>
    </>
  );
};

const styles = StyleSheet.create({
  traderTypeText: {
    fontWeight: "500",
    fontSize: 14,
    color: "#D0CFCE",
    lineHeight: 14 * 1.6,
    marginRight: 5,
  },
  traderTypeActive: {
    color: "#F37021",
  },
  traderTypeInactive: {
    color: "#D0CFCE",
  },
  botPlanTypeOneLabel: {
    marginLeft: 15,
    backgroundColor: 'red',
    paddingHorizontal: 15,
    borderRadius: 50,
    paddingVertical: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});
