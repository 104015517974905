import React from 'react'
import { components } from '../components';
import { ApSafeAreaView } from '../components/v1';
import { ScrollView, TouchableOpacity, View } from 'react-native';
import { Image } from 'react-native';
import { ApText } from '../components/v1/typography';
import { theme } from '../constants';
import { useNavigation } from '@react-navigation/native';

const Follow = () => {
  const navigation: any = useNavigation();

  const renderHeader = () => {
    return <components.Header title="Follow" titleTranlateKey="FollowTitle" />;
  };

  const renderContent = () => { 
    return (
      <ScrollView
        contentContainerStyle={{
          flexGrow: 1,
          paddingHorizontal: 20,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("FollowStrategy");
          }}
        >
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff5f0ba",
              marginTop: 10,
              borderRadius: 10,
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 15,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "85%",
              }}
            >
              <View style={{ width: 75 }}>
                <Image
                  source={require("../../assets/users/stable-str.png")}
                  style={{
                    width: 75,
                    height: 63,
                  }}
                />
              </View>
              <ApText
                style={{
                  color: theme.COLORS.mainDark,
                  fontWeight: "500",
                  paddingLeft: 15,
                }}
                translateKey='FollowStrategy'
              />
            </View>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            navigation.navigate("CopyTrading");
          }}
        >
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff5f0ba",
              marginTop: 10,
              borderRadius: 10,
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 15,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "85%",
              }}
            >
              <View style={{ width: 75 }}>
                <Image
                  source={require("../../assets/users/stable-str.png")}
                  style={{
                    width: 75,
                    height: 63,
                  }}
                />
              </View>
              <ApText
                style={{
                  color: theme.COLORS.mainDark,
                  fontWeight: "500",
                  paddingLeft: 15,
                }}
                translateKey='CopyTrading'
              />
            </View>
          </View>
        </TouchableOpacity>

        <TouchableOpacity
          onPress={() => {
            navigation.navigate("FXTradingCopyTrade");
          }}
        >
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff5f0ba",
              marginTop: 10,
              borderRadius: 10,
              flexDirection: "row",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 15,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                width: "85%",
              }}
            >
              <View style={{ width: 75 }}>
                <Image
                  source={require("../../assets/users/stable-str.png")}
                  style={{
                    width: 75,
                    height: 63,
                  }}
                />
              </View>
              <ApText
                style={{
                  color: theme.COLORS.mainDark,
                  fontWeight: "500",
                  paddingLeft: 15,
                }}
                translateKey='FXTradingCopyTrade'
              />
            </View>
          </View>
        </TouchableOpacity>
      </ScrollView>
    )
  }

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {renderContent()}
    </ApSafeAreaView>
  )
}

export default Follow