import { useNavigation } from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import { TextInput, TouchableOpacity, View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Icon from "react-native-vector-icons/FontAwesome";
import { components } from "../components";
import { ApCheckInput, ApEmptyState, ApLoader, ApPlatformTabs, ApRow, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useTradingContext } from "../context";
import { svg } from "../svg";
import { useCopyState } from "../context/copy";
import { ICopyTrade } from "../models/copy.interface";
import { useApiBindingState } from "../context/apiBinding";
import { IFXTradingProvider } from "../models";
import { Formik } from "formik";
import { t } from "i18next";
import { RefreshControl } from "react-native-web-refresh-control";
import Checkbox from "expo-checkbox";

const FXTradingCopyTrade = () => {
  const {loading, getFXTradingProviders, fXTradingProviders, setSelectedProvider} = useApiBindingState()
  const searchInputRef = useRef<TextInput>(null);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [query, setQuery] = useState<any>();
  const [showFollowing, setShowFollowing] = useState<boolean>(false);

  useEffect(() => {
    getFXTradingProviders();
  }, []);

  const renderHeader = () => {
    return (
      <components.Header
        goBack={true}
        title="FXTrading Copy Trade"
        titleTranlateKey="FXTradingCopyTrade"
      />
    );
  };

  return (
    <ApSafeAreaView
      style={{
        marginBottom: 20,
      }}
    >
      {renderHeader()}
      {loading ? (
        <ApLoader />
      ) : (
        <>
          <View style={{ marginHorizontal: 15, marginVertical: 10 }}>
            {/* <ApPlatformTabs selected={platform} onSelect={setPlatform} /> */}
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Icon name="warning" size={40} color="#fccc4d" />
                  <ApText
                    style={{
                      color: "#F37021",
                      fontSize: 10,
                      marginLeft: 5,
                      textAlign: "justify",
                      lineHeight: 14,
                    }}
                    translateKey="FollowPageNote"
                  />
                </View>

                <ApText
                  style={{
                    color: "red",
                    fontSize: 10,
                    textAlign: "justify",
                    lineHeight: 14,
                  }}
                  translateKey="FollowPageNote2"
                />

                
                <ApText
                  style={{
                    color: "red",
                    fontSize: 10,
                    textAlign: "justify",
                    lineHeight: 14,
                  }}
                  translateKey="FXTradingCopyTradePageHint"
                />
              </View>
            </View>

            <Formik 
              initialValues={{}}
              onSubmit={() => {}}
            >
              <>              
                <components.SearchInputField
                  inputRef={searchInputRef}
                  onChange={(e: any) => {
                    e.target.value === ""
                    ? setIsSearching(false)
                    : setIsSearching(true);
                    setQuery(e.target.value);
                  }}
                  containerStyle={{ marginVertical: 20 }}
                  leftIcon={<svg.SearchSvg />}
                  placeholder={t("CopyStrategySearchPlaceholder")}
                />

                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    // width: "100%",
                  }}
                >
                  <Checkbox
                    style={{
                      height: 13,
                      width: 13,
                      borderWidth: !showFollowing ? 1 : 0,
                      borderColor: "#F37021",
                    }}
                    value={showFollowing}
                    color={showFollowing ? "#F37021" : "transparent"}
                    onValueChange={(v) => {
                      setShowFollowing(v);
                    }}
                  />

                  <ApText
                    style={{ marginLeft: 10, fontSize: 14 }}
                    translateKey="ShowFollowing"
                  />
                </View>
              </>
            </Formik>
          </View>

          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={loading}
                onRefresh={() => getFXTradingProviders()}
              />
            }
          >
            <View style={{ paddingHorizontal: 15 }}>
              {fXTradingProviders.length === 0 ? (
                <ApEmptyState
                  heading="Follow"
                  message="No records found"
                />
              ) : (
                isSearching ? 
                (fXTradingProviders
                  .filter((item) => 
                    showFollowing ?
                    item?.is_following == 1 &&
                    (item?.label.toLowerCase()
                    .includes(query.toLowerCase())) ||
                    (item?.description.toLowerCase()
                    .includes(query.toLowerCase()))
                    : 
                    (item?.label.toLowerCase()
                    .includes(query.toLowerCase())) ||
                    (item?.description.toLowerCase()
                    .includes(query.toLowerCase()))
                )).map((t, i) => (
                  <CopyTradingItem trade={t} key={i} />
                )) 
                : showFollowing ? fXTradingProviders.filter((item) => item?.is_following == 1).map((t, i) => (
                  <CopyTradingItem trade={t} key={i} />
                ))
                : fXTradingProviders?.map((t, i) => (
                  <CopyTradingItem trade={t} key={i} />
                ))
              )}

              {isSearching &&
                (fXTradingProviders
                .filter((item) => 
                  showFollowing ?
                  item?.is_following == 1 &&
                  (item?.label.toLowerCase()
                  .includes(query.toLowerCase())) ||
                  (item?.description.toLowerCase()
                  .includes(query.toLowerCase()))
                  : 
                  (item?.label.toLowerCase()
                  .includes(query.toLowerCase())) ||
                  (item?.description.toLowerCase()
                  .includes(query.toLowerCase()))
                )).length === 0 && (
                <ApEmptyState
                  heading="Follow"
                  message="No records found"
                />
              )}

              {/* {fXTradingProviders?.map((t, i) => (
                <CopyTradingItem trade={t} key={i} />
              ))} */}
            </View>
          </ScrollView>
        </>
      )}
    </ApSafeAreaView>
  );
};

export default FXTradingCopyTrade;

interface IProps {
  trade: IFXTradingProvider;
}

const CopyTradingItem: React.FC<IProps> = ({ trade }) => {
  const navigation: any = useNavigation();
  const { platform } = useTradingContext();
  const [backTestResult, showBackTestResult] = useState(false);
  const { setSelectedProvider} = useApiBindingState()
  
  return (
    <View
      style={{
        justifyContent: "space-between",
        borderRadius: 50,
        backgroundColor: "#FFF6F1",
      }}
    >
      <View
        style={{
          padding: 10,
          paddingTop: 15,
          paddingBottom: 0,
          backgroundColor: "#FFF6F1",
          borderRadius: 10,
        }}
      >
        <View
          // key={`${index}_follow`}
          style={{
            padding: 15,
            borderRadius: 10,
            marginBottom: 20,
            borderWidth: 2,
            borderColor: "#F37021",
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <ApText
              style={{
                fontSize: 13,
                fontWeight: "500",
              }}
            >
              {trade.label}
            </ApText>

            <ApText
              style={{
                fontSize: 13,
                textAlign: "right",
                color: "#222",
              }}
            >
              {trade.description}
            </ApText>
          </View>

          <ApRow>
            <View
              style={{
                flexDirection: "row",
                paddingTop: 5,
                paddingBottom: 10,
              }}
            >
              <ApText
                style={{
                  fontSize: 12,
                  color: "#878787",
                  marginRight: 5,
                }}
              >
                {trade.follower_count}
              </ApText>
              <ApText
                translateKey="Followers"
                style={{ fontSize: 12, color: "#878787" }}
              />
            </View>
            
            {trade?.is_close_failed == 1 ? (
              <ApText
                style={{ fontSize: 12, color: 'red' }}
                translateKey="CloseOrderFailed"
              />
            ) : trade?.is_following == 1 && (
              <ApText
                style={{ fontSize: 12, color: theme.COLORS.green }}
                translateKey="Following"
              />
            )}
          </ApRow>

          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              borderBottomWidth: 1,
              borderBottomColor: "#CED6E1",
            }}
          />
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
            }}
          >
            <View style={{ flex: 1 }}>
              <ApText
                translateKey="new30dPLPerc"
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  lineHeight: 12 * 1.6,
                  color: theme.COLORS.bodyTextColor,
                  marginTop: 12,
                }}
              />
              <ApText
                style={{
                  marginBottom: 12,
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade?.pnl_amt_30d}%
              </ApText>
              <ApText
                translateKey="totalPLPerc"
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  lineHeight: 12 * 1.6,
                  color: theme.COLORS.bodyTextColor,
                }}
              />
              <ApText
                style={{
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.pnl_amt_total}%
              </ApText>
            </View>

            <View style={{ flex: 1 }}>
              <ApText
                translateKey="90dPLPerc"
                style={{
                  ...theme.FONTS.Mulish_400Regular,
                  fontSize: 12,
                  lineHeight: 12 * 1.6,
                  color: theme.COLORS.bodyTextColor,
                  marginTop: 12,
                }}
              />
              <ApText
                style={{
                  marginBottom: 12,
                  fontWeight: "500",
                  color: theme.COLORS.mainDark,
                  fontSize: 13,
                }}
              >
                {trade.pnl_amt_90d}%
              </ApText>
            </View>
          </View>

          {/* {backTestResult && <BackTestResult trade={trade} />} */}

          <View
            style={{
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: 10
            }}
          >
            {/* <TouchableOpacity
              onPress={() => showBackTestResult(!backTestResult)}
              style={{ flexDirection: "row" }}
            >
              <ApText
                size="xs"
                color="#F37021"
                translateKey="BackTestResult"
                font="bold"
              />

              <View
                style={{
                  marginLeft: !backTestResult ? 12 : 8,
                  marginTop: -3,
                  transform: [{ rotate: !backTestResult ? "-90deg" : "90deg" }],
                }}
              >
                <svg.GoBackSvg width={6} height={12} goBackColor="#F37021" />
              </View>
            </TouchableOpacity> */}

            <TouchableOpacity
              onPress={() => {
                setSelectedProvider(trade);
                navigation.navigate("FXTradingCopyTradeDetail", {
                  id: trade.id,
                  platform: platform,
                });
              }}
              style={{
                backgroundColor: "#F37021",
                width: 100,
                height: 30,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ApText
                translateKey="CopyTradeDetails"
                style={{
                  fontSize: 13,
                  color: theme.COLORS.white,
                  fontWeight: "500",
                }}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
};

interface IBackTestProps {
  trade: ICopyTrade;
}

const BackTestResult: React.FC<IBackTestProps> = ({ trade }) => {
  return (
    <View
      style={{
        padding: 8,
        borderRadius: 10,
        marginVertical: 15,
        borderWidth: 2,
        borderColor: "#F37021",
      }}
    >
      <ApRow justify="flex-start">
        <ApText size="xs" translateKey="BackTestPeriod" />

        <ApText size="xs" color="#F37021" font="bold">
          {" : "}
          {trade?.backtest_period}
        </ApText>
      </ApRow>

      <ApRow style={{ marginVertical: 10 }}>
        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="TotalNetProfit" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_pnl}%
          </ApText>
        </View>

        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="MaxDrawdown" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_drawdown}%
          </ApText>
        </View>

        <View style={{ alignItems: "center", width: "33%" }}>
          <ApText style={{ fontSize: 11 }} translateKey="TestedCurrency" />

          <ApText size="xs" color="#F37021" font="bold">
            {trade?.backtest_symbol}
          </ApText>
        </View>
      </ApRow>

      <View>
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultFirstLabel"
        />
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultSecondLabel"
        />
        <ApText
          size="xs"
          style={{ marginBottom: 5 }}
          translateKey="BackTestResultThirdLabel"
        />
        <ApText size="xs" translateKey="BackTestResultFourthLabel" />
      </View>
    </View>
  );
};