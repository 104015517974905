import * as Clipboard from "expo-clipboard";
import * as Linking from "expo-linking";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image, ImageBackground, View } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import QRCode from "react-native-qrcode-svg";
import { components } from "../components";
import { ApButton, ApLoader, ApSafeAreaView } from "../components/v1";
import { ApText } from "../components/v1/typography";
import { theme } from "../constants";
import { useProfileState } from "../context";

const Referral: React.FC = ({ navigation }: any) => {
  const { profileData, getProfileData, loading } = useProfileState();
  const { i18n, t } = useTranslation();
  const [referralLink, setReferralLink] = useState("");
  const url = Linking.createURL("/");

  useEffect(() => {
    if (!profileData.referral_code && !profileData.referral_link)
      getProfileData();
  }, []);

  useEffect(() => {
    setReferralLink(
      `${url}registration?ref=${profileData?.referral_code}&language=${profileData.preferred_lang}`
    );
  }, [profileData]);

  const renderHeader = () => {
    return (
      <components.Header
        title={"Referral"}
        titleTranlateKey="Referral"
        goBack={true}
      />
    );
  };

  const renderContent = () => {
    return (
      <ImageBackground
        source={require("../../assets/bg/08.png")}
        imageStyle={{
          width: "100%",
        }}
        style={{ flex: 1 }}
      >
        <KeyboardAwareScrollView
          contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20 }}
        >
          <View style={{ paddingTop: theme.SIZES.height * 0.05 }}>
            <Image
              source={require("../../assets/referral-image.png")}
              style={{
                width: 130,
                height: 130,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <View
              style={{
                backgroundColor: theme.COLORS.white,
                borderRadius: 10,
                paddingHorizontal: 20,
                paddingTop: 14,
                paddingBottom: 20,
                marginBottom: 20,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  marginBottom: 8,
                }}
              >
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginVertical: 15,
                      borderColor: "#F8CCC6",
                      borderWidth: 2,
                      padding: 10,
                    }}
                  >
                    {referralLink && <QRCode value={referralLink} size={130} />}
                  </View>

                  <ApText
                    style={{
                      fontSize: 13,
                      color: "#ccc",
                      fontWeight: "500",
                      textAlign: "center",
                    }}
                  >
                    {t("ScanCodeDownload")}
                  </ApText>
                  <View style={{ paddingTop: 30 }}>
                    <ApText
                      style={{
                        fontSize: 15,
                        color: "#F37021",
                        fontWeight: "bold",
                        marginBottom: 5,
                      }}
                    >
                      {t("InvitationCode")}
                    </ApText>
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <ApText
                        style={{
                          fontSize: 14,
                          color: "#ccc",
                          fontWeight: "500",
                        }}
                      >
                        {profileData?.referral_code
                          ? profileData.referral_code
                          : "Not Available"}
                      </ApText>

                      <ApButton
                        labelStyle={{ fontSize: 13 }}
                        style={{
                          height: 35,
                          paddingHorizontal: 15,
                          marginTop: 0,
                        }}
                        round="md"
                        label="Copy"
                        labelTranslateKey="Copy"
                        onPress={() => {
                          Clipboard.setStringAsync(
                            profileData?.referral_code as string
                          );
                        }}
                      />
                    </View>

                    <View style={{ paddingTop: 35 }}>
                      <ApText
                        style={{
                          fontSize: 15,
                          color: "#F37021",
                          fontWeight: "bold",
                          marginBottom: 5,
                        }}
                      >
                        {t("InvitationLink")}
                      </ApText>
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <ApText
                          style={{
                            fontSize: 14,
                            maxWidth: 200,
                            color: "#ccc",
                            fontWeight: "500",
                            alignSelf: "center",
                          }}
                        >
                          {profileData?.referral_code
                            ? referralLink
                            : "Not Available"}
                        </ApText>

                        <ApButton
                          labelStyle={{ fontSize: 13 }}
                          style={{
                            height: 35,
                            paddingHorizontal: 15,
                            marginTop: 0,
                          }}
                          round="md"
                          label="Copy"
                          labelTranslateKey="Copy"
                          onPress={() => {
                            Clipboard.setStringAsync(referralLink);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    );
  };

  return (
    <ApSafeAreaView>
      {renderHeader()}
      {loading && <ApLoader />}
      {!loading && renderContent()}
    </ApSafeAreaView>
  );
};

export default Referral;
